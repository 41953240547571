<template>
  <div class="chat__contacts">
    <div class="chat__contacts">
      <ul class="chat__contacts">
        <li
          @click="updateActiveChatUser(contact)"
          class="cursor-pointer hovered"
          v-for="(contact, index) in activeChats"
          v-if="contact"
          :key="index"
          v-show="contact.wasTaked != true && contact.status != 0"
        >
          <!-- <pre>{{ contact.status }} {{ contact._id }}</pre> -->
          <chat-contact
            :key="contact._id"
            v-if="contact.wasTaked != true && contact.status != 0"
            :contact="contact"
            :unseenMsg="contact.unseenMsg"
          ></chat-contact>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ChatContact from './ChatContact.vue'

export default {
  name: 'ChatAttention',
  props: {
    activeChats: {
      Type: Array,
      required: true
    }
  },
  components: {
    ChatContact
  },
  methods: {
    updateActiveChatUser(contact) {
      this.$emit('updateActiveChatUser', contact)
    }
  }
}
</script>
